<template>
  <div class="container">
    <div class="main">
      <yanzheng />
      <div class="zezhao" v-if="zezhao||weihu">
        <div class="zezhao_tip" v-if="zezhao">
          <section class="zz-header">温馨提示</section>
          <div class="zz_content">
            <section style="height:1px"></section>
            <img src="../../assets/closewarn.png" class="closewarn" alt="">
            <h2>{{currentGame.Gamename}}现已关盘</h2>
            <p>下期开盘时间为</p>
            <p class="green">{{kaipanTime}}</p>
          </div>
        </div>
        <div class="zezhao_tip" v-if="weihu">
          <section class="zz-header">温馨提示</section>
          <div class="zz_content">
            <section style="height:1px"></section>
            <img src="../../assets/closewarn.png" class="closewarn" alt="">
            <h2>{{currentGame.Gamename}}正在维护</h2>

          </div>
        </div>

      </div>
      <top-timer></top-timer>
      <div class="player listmain type-lhc twlh game-twlh game-lhc-sb">
        <!-- top开始 -->
        <div class="data ctop">
          <h3>{{rowsData[0].title}}</h3>
          <ul>
            <li :class="ele.selected?'selected':''" @click="setCurrentSelected(ele)" :balls="'sb_'+ele.color" v-for="(ele,index) in rowsData[0].arr" :key="ele.ResultID">
              <ol class="td_name">
                {{ele.label}}
              </ol>
              <ol class="td_rate">
                {{ele.Odds}}
              </ol>
              <ol style="width: 600px" class="td_balls" v-if="index==2">
                <div class="greenball b5"></div>
                <div class="greenball b6"></div>
                <div class="greenball b11"></div>
                <div class="greenball b16"></div>
                <div class="greenball b17"></div>
                <div class="greenball b21"></div>
                <div class="greenball b22"></div>
                <div class="greenball b27"></div>
                <div class="greenball b28"></div>
                <div class="greenball b32"></div>
                <div class="greenball b33"></div>
                <div class="greenball b38"></div>
                <div class="greenball b39"></div>
                <div class="greenball b43"></div>
                <div class="greenball b44"></div>
                <div class="greenball b49"></div>
              </ol>
              <ol style="width: 600px" class="td_balls" v-if="index==1">
                <div class="blueball b3"></div>
                <div class="blueball b4"></div>
                <div class="blueball b9"></div>
                <div class="blueball b10"></div>
                <div class="blueball b14"></div>
                <div class="blueball b15"></div>
                <div class="blueball b20"></div>
                <div class="blueball b25"></div>
                <div class="blueball b26"></div>
                <div class="blueball b31"></div>
                <div class="blueball b36"></div>
                <div class="blueball b37"></div>
                <div class="blueball b41"></div>
                <div class="blueball b42"></div>
                <div class="blueball b47"></div>
                <div class="blueball b48"></div>
              </ol>
              <ol style="width: 600px" class="td_balls" v-if="index==0">
                <div class="redball b1"></div>
                <div class="redball b2"></div>
                <div class="redball b7"></div>
                <div class="redball b8"></div>
                <div class="redball b12"></div>
                <div class="redball b13"></div>
                <div class="redball b18"></div>
                <div class="redball b19"></div>
                <div class="redball b23"></div>
                <div class="redball b24"></div>
                <div class="redball b29"></div>
                <div class="redball b30"></div>
                <div class="redball b34"></div>
                <div class="redball b35"></div>
                <div class="redball b40"></div>
                <div class="redball b45"></div>
                <div class="redball b46"></div>
              </ol>
              <ol class="td_cash">
                <input type="text" v-model="ele.money" @click.stop="showOrHideCheck($event,ele)" />
                <div class="quick-check" v-if="ele.b">
                  <ul>
                    <li v-for="qs in quickSelectedData" :key="qs.num" @click.stop="setCurrentName(ele, qs.num)">
                      下注{{ qs.num }}元
                    </li>

                    <li @click="closeCheck(ele)">关闭</li>
                  </ul>
                </div>
              </ol>
            </li>
          </ul>
        </div>
        <!-- top结束 -->
        <!-- 半波 -->
        <template v-for="(item,index) in rowsData">
          <div class="data clsb" :key="item.title" v-if="index>0">
            <h3>{{item.title}}</h3>
            <ul class="custome_ul">
              <li :class="ele.selected?'selected':''" @click="setCurrentSelected(ele)" :balls="'sb_' + ele.color" v-for="ele in item.arr" :key="ele.ResultID">
                <ol class="td_name">
                  {{
                  ele.label
                }}
                </ol>
                <ol class="td_rate">
                  {{
                  ele.Odds
                }}
                </ol>
                <ol class="td_cash">
                  <input type="number" v-model="ele.money" @click.stop="showOrHideCheck($event,ele)" />
                  <div class="quick-check" v-if="ele.b">
                    <ul>
                      <li v-for="qs in quickSelectedData" :key="qs.num" @click.stop="setCurrentName(ele, qs.num)">
                        下注{{ qs.num }}元
                      </li>

                      <li @click="closeCheck(ele)">关闭</li>
                    </ul>
                  </div>
                </ol>
              </li>
            </ul>
          </div>
        </template>

        <!-- 半半波 -->
      </div>
      <yushe />
    </div>
    <div class="other">
      <div class="zezhao" v-if="zezhao||weihu"></div>
      <div class="right-com">
        <div class="rc-tab">
          <span :class="changelongActive == '1' ? 'selected' : ''" @click="setLongTab('1')">两面长龙排行</span>
          <!-- <span :class="changelongActive == '2' ? 'selected' : ''" @click="setLongTab('2')">全部长龙</span> -->
        </div>
        <div class="rc-tab-long" v-if="changelongActive=='1'">
          <!-- <div class="long-isbz">
            <span @click="setKaiTab('1')" :class="kaiActive == '1' ? 'selected' : ''">连续开奖</span>
            <span @click="setKaiTab('2')" :class="kaiActive == '2' ? 'selected' : ''">连续未开</span>
          </div> -->
          <ul class="longlist" v-if="kaiActive=='1'">
            <li v-for="item in rclData" :key="item.ID">
              <span>{{item.info}}</span>
              <b class="red">{{item.num}}期</b>
            </li>
          </ul>
          <ul class="longlist" v-if="kaiActive=='2'">
            <li v-for="item in lclData" :key="item.ID">
              <span>{{item.info}}</span>
              <b class="blue">{{item.num}}期</b>
            </li>
          </ul>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import topTimer from "../mainHeader.vue";
import yanzheng from "../yanzheng.vue";
import yushe from "../yushe.vue";
import { mapState } from "vuex";
import mixins from "../mixins/mainMixins";
export default {
  name: "",
  props: [""],
  mixins: [mixins],
  data() {
    return {
      groupnames:'sb',
      // 长龙 tab
      changelongActive: "1",
      // 连续开  连续未开 tab
      kaiActive: "1",
      rowsData: [
        { title: "色波", arr: [] },
        { title: "半波", arr: [] },
        { title: "半半波", arr: [] },
      ],

      colorArr1: ["red", "blue", "green"],
      colorArr2: [
        "red",
        "red",
        "red",
        "red",
        "blue",
        "blue",
        "blue",
        "blue",
        "green",
        "green",
        "green",
        "green",
      ],
      labelArr1: ["红波", "蓝波", "绿波"],
      labelArr2: [
        "红大",
        "红小",
        "红单",
        "红双",
        "蓝大",
        "蓝小",
        "蓝单",

        "蓝双",
        "绿大",
        "绿小",
        "绿单",

        "绿双",
      ],
      labelArr3: [
        "红大单",
        "红大双",
        "红小单",
        "红小双",
        "蓝大单",
        "蓝大双",
        "蓝小单",
        "蓝小双",
        "绿大单",

        "绿大双",

        "绿小单",

        "绿小双",
      ],
      xiazhuList: [],
    };
  },
  watch: {
    // 判断是否可以点击确定提交按钮
    rowsData: {
      deep: true,
      handler(val) {
        this.$nextTick(() => {
          let arr = [];
          val.forEach((item) => {
            item.arr.forEach((ele) => {
              if (ele.money) {
                let obj = {
                  label: ele.label,
                  Odds: ele.Odds,
                  title: item.title,
                  id: ele.ResultID,
                  money: Number(ele.money),
                };
                arr.push(obj);
              }
            });
          });

          // console.log(arr, "arrrr");
          if (arr.length > 0) {
            this.$store.commit("setXZList", arr);
          } else {
            this.$store.commit("setXZList", []);
          }
        });
      },
    },
    // j监听数据
    oddData1: {
      immediate: true,
      deep: true,
      handler(resAArr) {
        let arr1 = this.ddFilterData1(resAArr, [795, 797, 796], this.labelArr1);
        arr1.forEach((item, index) => {
          item.color = this.colorArr1[index];
        });
        this.rowsData[0].arr = arr1;
        let arr2 = this.ddFilterData1(
          resAArr,
          [690, 691, 688, 689, 700, 701, 698, 699, 710, 711, 708, 709],
          this.labelArr2
        );
        arr2.forEach((item, index) => {
          item.color = this.colorArr2[index];
        });
        this.rowsData[1].arr = arr2;
        let arr3 = this.ddFilterData1(
          resAArr,
          [694, 696, 695, 697, 704, 706, 705, 707, 714, 716, 715, 717],
          this.labelArr3
        );
        arr3.forEach((item, index) => {
          item.color = this.colorArr2[index];
        });
        this.rowsData[2].arr = arr3;
        this.$forceUpdate();
      },
    },
  },

  components: {
    topTimer,
    yushe,
    yanzheng,
  },
  computed: {
    //  预设按钮是否能点击 筛选数据传到下注弹框
    isDisabled() {
      let b = true;
      this.xiazhuList = [];
      // this.rowsData.forEach((item) => {
      //   item.forEach((ele) => {
      //     if (ele.name) {
      //       this.xiazhuList.push(ele);
      //       b = false;
      //     }
      //   });
      // });
      if (!b) {
        this.$store.commit("setXZList", this.xiazhuList);
      } else {
        this.$store.commit("setXZList", []);
      }
      return b;
    },
    ...mapState(["amount", "quickSelectedData", "quick_c", "quick_b"]),
  },
  created() {},

  methods: {
    setLongTab(n) {
      this.changelongActive = n;
    },
    setKaiTab(n) {
      this.kaiActive = n;
    },

    focusQS(e) {
      console.log(e);
    },
  },
};
</script>
 <style scoped>
.custome_ul {
  height: 104px;
  flex-direction: column !important;
}
.listmain div.ctop ul li {
  width: 100%;
}

.listmain .data ul li {
  width: 33.33%;
  height: 26px;
  max-height: 26px;
}
</style>